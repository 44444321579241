<template>
  <div class="replace">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />
    <div class="replace_search_id">
      <el-select
        v-model="valueArea"
        filterable
        clearable
        placeholder="请选择区号(可搜索关键字)"
        style="width: 240px; margin-bottom: 20px"
        v-if="internationType"
      >
        <el-option
          v-for="item in options"
          :key="item.name"
          :label="item.name + item.tel"
          :value="item.tel"
        >
        </el-option>
      </el-select>
      <div style="display: flex">
        <el-input v-model="searchPhone" placeholder="查询手机号"></el-input>
        <el-button type="primary" @click="handleSearchPhone">查询</el-button>
      </div>
      <div class="replace_search_id_p">查询到的盒子id是：{{ boxid }}</div>
    </div>
    <el-form
      :model="ruleForm"
      status-icon
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item prop="phoneArea">
        <el-select
          v-model="valueArea2"
          filterable
          clearable
          placeholder="请选择区号(可搜索关键字)"
          style="width: 240px"
          v-if="internationType"
        >
          <el-option
            v-for="item in options"
            :key="item.name"
            :label="item.name + item.tel"
            :value="item.tel"
          >
          </el-option> </el-select
      ></el-form-item>
      <el-form-item prop="phone">
        <el-input
          v-model="ruleForm.phone"
          placeholder="请输入手机号"
        ></el-input>
      </el-form-item>
      <el-form-item prop="id">
        <el-input v-model="ruleForm.id" placeholder="请输入新盒子ID"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >强制修改</el-button
        >
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { showLoading, hideLoading } from "@/util/loading";
import { areaCodeData } from "@/util/areaCodeData";

export default {
  components: {
    Breadcrumb,
  },
  data() {
    var validatePhone = (rule, value, callback) => {
      var phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!value) {
        return callback(new Error("号码不能为空!!"));
      }
      setTimeout(() => {
        if (!phoneReg.test(value)) {
          callback(new Error("手机号格式有误"));
        } else {
          callback();
        }
      }, 100);
    };

    return {
      ruleForm: {
        phone: "",
        id: "",
      },

      rules: {
        phone: [{ validator: validatePhone, trigger: "blur" }],
      },

      valueArea: "",
      valueArea2: "",
      options: [],
      searchPhone: "",
      boxid: "",

      BreadcrumbCon: [
        {
          name: "行程上链",
        },
        {
          name: "趣宝盒数据",
        },
        {
          name: "强制绑定/替换盒子ID",
        },
      ], // 面包屑数据

      internationType: false,
      role: "",
      phoneAreaROLE: "",
    };
  },

  mounted() {
    this.options = areaCodeData;

    this.role = localStorage.getItem("ROLE");

    var strNation = this.role.includes("ROLE_nation");
    if (strNation) {
      this.internationType = true;
    }
    this.phoneAreaROLE = localStorage.getItem("phoneArea");
  },

  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          showLoading();

          const opt = {
            url: reqUrl.queryBoxIsUsedUrl,
            method: "POST",
            params: JSON.stringify({
              field: this.ruleForm.id,
              nonce: "",
              phone: this.ruleForm.phone,
              sign: "",
              token: "",
            }),
            resFunc: (res) => {
              console.log(res);
              if (!res.data.data) {
                console.log("无usingPhone");
                const opt = {
                  url: reqUrl.updateBoxidUrl,
                  method: "POST",
                  params: JSON.stringify({
                    field: this.ruleForm.id,
                    nonce: "",
                    phone: this.ruleForm.phone,
                    phoneArea: this.valueArea2 || this.phoneAreaROLE,
                    sign: "",
                    token: "",
                  }),
                  resFunc: (res) => {
                    console.log(res.data);
                    if (res.data.code != 0) {
                      this.$notify.error({
                        title: "错误",
                        message: res.data.message,
                      });
                      hideLoading();
                    }
                    if (res.data.code == 0) {
                      this.$message({
                        message: "修改成功",
                        type: "success",
                      });
                      this.ruleForm.phone = "";
                      this.ruleForm.id = "";
                      hideLoading();
                    }
                    hideLoading();
                  },
                  errFunc: (err) => {
                    this.$notify.error({
                      title: "错误",
                      message: err,
                    });
                    hideLoading();
                    console.log(err, "err1");
                  },
                };
                request(opt);
              } else if (res.data.data.usingPhone) {
                this.$confirm(
                  `该盒子已被${res.data.data.usingPhone}用户绑定，是否继续?`,
                  "提示",
                  {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                  }
                )
                  .then(() => {
                    const opt = {
                      url: reqUrl.updateBoxidUrl,
                      method: "POST",
                      params: JSON.stringify({
                        field: this.ruleForm.id,
                        nonce: "",
                        phone: this.ruleForm.phone,
                        phoneArea: this.valueArea2 || this.phoneAreaROLE,
                        sign: "",
                        token: "",
                      }),
                      resFunc: (res) => {
                        if (res.data.code != 0) {
                          this.$notify.error({
                            title: "错误",
                            message: res.data.message,
                          });
                          hideLoading();
                        } else if (res.data.code == 0) {
                          this.$message({
                            message: "修改成功",
                            type: "success",
                          });
                          this.ruleForm.phone = "";
                          this.ruleForm.id = "";
                          hideLoading();
                        }
                      },
                      errFunc: (err) => {
                        this.$notify.error({
                          title: "错误",
                          message: err,
                        });
                        hideLoading();
                        console.log(err, "err1");
                      },
                    };
                    request(opt);
                  })
                  .catch(() => {
                    this.$message({
                      type: "info",
                      message: "已取消操作",
                    });
                  });
                hideLoading();
              }
              hideLoading();
            },
            errFunc: (err) => {
              this.$notify.error({
                title: "错误",
                message: err,
              });
              hideLoading();
              console.log(err, "err1");
            },
          };
          request(opt);
        } else {
          this.$notify.error({
            title: "错误",
            message: "请检查输入是否正确",
          });
          return false;
        }
      });
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    handleSearchPhone() {
      showLoading();
      const opt = {
        url: reqUrl.selectBoxidUrl,
        method: "POST",
        params: JSON.stringify({
          nonce: "",
          phone: this.searchPhone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          sign: "",
          token: "",
        }),
        resFunc: (res) => {
          console.log(res.data);
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            this.boxid = res.data.data.boxid;
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
          console.log(err, "err1");
        },
      };
      request(opt);
    },
  },
};
</script>

<style lang="scss" scoped>
.replace {
  width: 100%;

  .el-form {
    .el-input {
      width: 280px;
    }
  }

  .replace_search_id {
    margin-left: 100px;
    margin-bottom: 25px;

    .el-input {
      width: 280px;
      margin-right: 10px;
    }

    .replace_search_id_p {
      color: #333;
      font-size: 14px;
      margin-top: 10px;
    }
  }
}

::v-deep .el-input.is-disabled .el-input__inner {
  background-color: #e6e7e7;
  border-color: #d3d4d6;
  color: #7c7d7e;
}
</style>
